.login-maskott-img {
    width: clamp(100px, 40%, 200px);
    margin: auto;
}

.add-todo-close-icon {
    position: absolute;
    color: white;
    top: 5px;
    right: 5px;
    font-size: 30px;
    cursor: pointer;
    user-select: none;
    transition: all .2s ease;
}

.add-todo-close-icon:hover {
    transform: scale(1.2);
}

.fancy-form-todo {
    position: relative;
    border-radius: 10px;
    margin: 30px auto;
    margin-bottom: 50px;
    padding: 20px 50px;
    display: flex;
    flex-direction: column;
    background-color: white;
    max-width: 400px;
    gap: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.fancy-form-title {
    text-align: center;
    font-weight: 700;
    font-size: 30px;
    color: var(--primary-color);
}

.fancy-input-container {
    position: relative;
    display: flex;
    align-items: center;
}

.fancy-form-todo input {
    width: 100%;
    padding: 10px 15px;
    border: 1px solid rgb(136, 136, 136);
    background-color: white;
    font-size: 16px;
    font-weight: 400;
    transition: 300ms ease;
    border-radius: 5px;
}

.fancy-form-todo input:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.fancy-form-todo input:hover {
    border: 1px solid var(--primary-color);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.fancy-form-todo label {
    position: absolute;
    display: flex;
    align-items: center;
    height: 50%;
    left: 10px;
    padding: 0 5px;
    transition: 300ms ease;
    pointer-events: none;
    font-size: 16px;
}

.fancy-input-container input:focus + label, 
.fancy-input-container input:valid + label {
    transform: translateY(-100%);
    color: var(--primary-color);
    font-size: 14px;
    background-color: white;
}

.fancy-form-todo button {
    width: 100%;
    border-radius: 30px;
    border: none;
    padding: 10px;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 16px;
    color: rgb(255, 255, 255);
    background: var(--primary-color);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 10px;
    cursor: pointer;
    outline: none;
    transition: transform .2s ease, box-shadow .2s ease, filter .2s ease;
}

.fancy-form-todo button:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 10px;
    filter: brightness(1.1);
}

.fancy-form-todo button:active {
    transform: scale(0.95);
}

.fancy-form-todo button:focus {
    outline: 3px solid black;
}

.corner-bg-icon {
    position: absolute;
    width: 50px;
    height: 50px;
    background: var(--primary-color);
    top: 0;
    right: 0;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 50px;
}

@media screen and (max-width: 500px) {
    .fancy-form-todo button:focus {
        outline: none;
    }

    .fancy-form-todo button:hover {
        box-shadow: none;
        filter: none;
    }
}