@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "~@flaticon/flaticon-uicons/css/all/all";

* {
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

/* Fjerner scrollbaren */
body::-webkit-scrollbar {
    display: none; 
  }

*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Dette fjerner chrome sin bakgrunnsfarge på autocomplete valg for inputs */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* html {
    height: -webkit-fill-available;
} */

#root {
    position: relative;
    padding-top: 90px;
    min-height:100vh;
    min-height:100dvh;
/*     min-height: -webkit-fill-available; */
}

:root {
    --primary-color: #0abd6c;
    --secondary-color: #20d885;
}

.standard-h1 {
    font-size: 50px;
    text-align: center;
    color: var(--primary-color);
}

/* HOME */
.home-container-div {
    position: relative;
    height: calc(100vh - 90px);
    height: calc(100dvh - 90px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
} 

.home-container-div .home-bg-img {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -1;
}

.welcome-container {
    margin: 30px;
    max-width: 1000px;
    padding: 30px;
    border-radius: 10px;
    color: rgb(53, 53, 53);
    background: rgba(255, 255, 255, 0.9);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px;
    transition: box-shadow .2s ease;
    position: relative;
}

.welcome-container .arrows {
    cursor: pointer;
    font-size: 40px;
    position: absolute;
}

.welcome-container .arrows.left-arrow {
    left: -35px;
    top: 42.5%;
}

.welcome-container .arrows.right-arrow {
    right: -35px;
    top: 42.5%;
}

.welcome-container h2 {
    text-align: center;
    margin-bottom: 10px;
}

.welcome-container h4 {
    margin-top: 20px;
    text-align: left;
    font-size: 20px;
}

.welcome-container p {
    font-size: 14px;
}

.welcome-container span {
    font-weight: 700;
}

/* List styling */
.single-list-container {
    padding: 0 30px;
}

.list-info-container {
    background: rgb(255, 255, 255);
    max-width: 1000px;
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: 1fr 2fr 1fr;
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    position: relative;
    margin: 30px auto;
    border-bottom: 2px solid transparent;
    text-align: center;
}

.list-info-container .under-text {
    color: rgb(145, 145, 145);
}

.list-info-container .list-title  {
    background: var(--primary-color);
    background: linear-gradient(to right, var(--primary-color) 50%, #000000 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* Custom Dropdown */
.select-wrapper {
    display: grid;
    place-items: center;
    gap: 20px;
}

select {
    font-size: 20px;
    letter-spacing: 1px;
    padding: 10px 40px 10px 20px;
    background: var(--primary-color);
    color: white;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
    transition: .3s;
}

select:active,
select:focus,
select:hover {
    border: none;
    outline: none;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.custom-select {
    position: relative;
}

.custom-arrow {
    position: absolute;
    top: 0;
    right: 0;
    background: var(--primary-color);
    display: block;
    height: 1rem;
    width: 3rem;
    height: 100%;
    pointer-events: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.custom-arrow::before,
.custom-arrow::after {
    --size: 8px;
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: 50%;

    transform: translate(-50%, -50%);
}

.custom-arrow::before {
    border-left: var(--size) solid transparent;
    border-right: var(--size) solid transparent;
    border-bottom: var(--size) solid rgba(255, 255, 255, 0.7);
    top: 35%;
}

.custom-arrow::after {
    border-left: var(--size) solid transparent;
    border-right: var(--size) solid transparent;
    border-top: var(--size) solid rgba(255, 255, 255, 0.7);
    top: 65%;
}

.custom-select-button {
    width: 200px;
    border-radius: 30px;
    border: 2px solid transparent;
    padding: 10px;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 16px;
    color: rgb(255, 255, 255);
    background: rgb(0, 195, 255);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 10px;
    cursor: pointer;
    outline: none;
    transition: box-shadow .4s ease;
}

.custom-select-button:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 10px;
    background: rgb(0, 145, 189);
}

.custom-select-button:focus {
    border: 2px solid black;
}

/* Add List elements show hide */
.add-list-form {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 30px auto;
    width: 68px;
    height: 68px;
    gap: 25px;
}

  .add-list-form[data-isopen="true"] {
    width: clamp(200px, 100%, 400px);
    border-radius: 10px;
    padding: 20px 60px 20px 60px;
    margin: 50px auto;
    height: auto;
    position: relative;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.add-list-form-title {
    text-align: center;
    font-weight: 700;
    font-size: 30px;
    color: var(--primary-color)
}

.add-list-form-container {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
}

.add-list-form input {
    width: 100%;
    padding: 10px 15px;
    border: 1px solid rgb(136, 136, 136);
    background-color: white;
    font-size: 16px;
    font-weight: 400;
    transition: 300ms ease;
    border-radius: 5px;
}

.add-list-form input:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.add-list-form input:hover {
    border: 1px solid var(--primary-color);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.add-list-form label {
    position: absolute;
    display: flex;
    align-items: center;
    height: 50%;
    left: 10px;
    padding: 0 5px;
    transition: 300ms ease;
    pointer-events: none;
    font-size: 16px;
}

.add-list-form-container input:focus + label, 
.add-list-form-container input:valid + label {
    transform: translateY(-100%);
    color: var(--primary-color);
    font-size: 14px;
    background-color: white;
}

.add-list-form button {
    width: 100%;
    border-radius: 30px;
    border: none;
    padding: 10px;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 16px;
    color: rgb(255, 255, 255);
    background: var(--primary-color);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 10px;
    cursor: pointer;
    outline: none;
    transition: all .2s ease;
}

.add-list-form button:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 10px;
    filter: brightness(1.2);
}

.add-list-form button:active {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 10px;
    filter: brightness(1.2);
    transform: scale(0.95);
}

.add-list-form button:focus {
    outline: 3px solid black;
}

.material-symbols-rounded.close-list {
    position: absolute;
    color: rgb(255, 255, 255);
    top: 5px;
    right: 5px;
    font-size: 30px;
    cursor: pointer;
    user-select: none;
    transition: all .2s ease;
}

.material-symbols-rounded.close-list:hover {
    transform: scale(1.2);
}

.corner-bg-icon-add-list {
    position: absolute;
    width: 50px;
    height: 50px;
    background: var(--primary-color);
    top: 0;
    right: 0;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 50px;
}

/* Icons */
.buttons-container {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 20px;
}

.button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 90px;
    border-radius: 10px;
    color:rgb(180, 180, 180);
    font-size: 14px;
    transition: all .2s ease;
    cursor: pointer;
    user-select: none;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.button-container:hover {
    transform: scale(1.2);
    color: black;
}
.button-container:active {
    transform: scale(1);
}

.button-container-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.material-symbols-rounded {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}

.icons {
    cursor: pointer;
    user-select: none;
    transition: transform .2s ease-in-out;
}

.icons:hover {
    transform: scale(1.2);
}

.todo-form-icon {
    font-size: 80px;
    cursor: pointer;
    user-select: none;
    transition: transform .2s ease-in-out;
    display: block;
    -webkit-tap-highlight-color: transparent;  
}

.todo-form-icon:hover {
    transform: scale(1.2);
}

.todo-form-icon.add {
    color: var(--primary-color)6C;
}

.todo-form-icon.remove {
    color: rgb(255, 0, 0);
}

.add-icon-container {
    display: flex;
    justify-content: center;
    margin: 20px;
}

.profile-icon {
    font-size: 50px;
    user-select: none;
    text-align: center;
    width: 100%;
    background-color: rgb(248, 245, 245);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px;
    display: block;
    border-bottom: 1px solid rgba(216, 216, 216, 0.396);
}

/* Common Button Styling */
.button {
    width: 250px;
    padding: 10px;
    border-radius: 30px;
    border: none;
}

/* List Overview */
.todo-list-page-padding {
    padding: 30px 30px;
    min-height: calc(100vh - 90px);
    min-height: calc(100dvh - 90px);
}

.todo-list-page-padding p {
    text-align: center;
}

.list-overview-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin: 30px auto;
    max-width: 1000px;
}

.singlelist-overview-container {
    background: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    color: var(--primary-color);
    text-decoration: none;
    font-weight: 300;
    font-size: 20px;
    letter-spacing: 1px;
    user-select: none;
}

.singlelist-overview-container input {
    color: var(--primary-color);
    font-size: 20px;
    width: 100%;
    border: none;
    outline: 1px solid black;
    letter-spacing: 1px;
    font-weight: 300;
}

.singlelist-overview-container:hover {
    box-shadow: rgba(50, 93, 50, 0.25) 0px 6px 12px -2px, var(--primary-color) 0px 3px 7px -3px;
}

.singlelist-overview-container input::placeholder {
    color: grey;
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: 300;
}

.singlelist-overview-container .list-icons-flex {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
    height: 100%;
    margin-left: 20px;
}

.singlelist-overview-container .list-overview-icon {
    color: black;
    transition: .2s;
    cursor: pointer;
}

.singlelist-overview-container .list-overview-icon:hover {
    color: black;
    transform: scale(1.3);
}

/* Liste drag and delete */ 
.drag-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: clamp(200px, 50%, 600px);
    height: 70px;
    margin: 20px auto;
    border: 2px dotted red;
    background-color: #ff000015;
    text-align: center;
    font-size: 40px;
    border-radius: 10px;
}

.drag-container .delete-drag {
    font-size: 40px;
    color: rgb(0, 0, 0);
    user-select: none;
    color: black;
} 

/* About */ /* Profile */
.headline {
    font-size: 25px;
    font-weight: 600;
}

.bold-text {
    font-weight: 700;
}

#idea-icon {
    font-size: 50px;
    color: rgb(223, 223, 223);
    margin-right: 20px;
    user-select: none;
}

.about-padding {
    display: flex;
    flex-direction: column;
    padding: 30px;
    gap: 0px;
    justify-content: flex-start;
    min-height: calc(100vh - 90px);
    min-height: calc(100dvh - 90px);
}

.container.about {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: rgba(149, 157, 165, 0.5) 0px 2px 5px;
    border-radius: 10px;
    position: relative;
    border-bottom: 2px solid var(--primary-color);
    display: flex;
    align-items: center;
}

.container.about span {
    font-weight: 700;
}

.image-container-maskott {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.liten-maskott {
    max-width: 100px;
    display: block;
}

/* Navbar */
.navbar {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 90px;
    background: var(--primary-color);
    z-index: 2000;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 10px;
}

.navbar-header {
    width: 100%;
    height: 100%;
    padding: 20px 40px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
}

.navbar-header .profile-name a {
    color: white;
    text-decoration: none;
}

.navbar-header .profile-link {
    justify-self: center;
}

.navbar-header .logo {
    justify-self: flex-end;
}

.navbar .logo a {
    font-size: 20px;
    text-decoration: none;
    color: black;
}

.navbar .logo span {
    color: #ffffff;
    font-weight: 700;
    letter-spacing: 1.5px;
}

/* SIDEBAR CSS */
.navbar-maskott-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.navbar-maskott {
    width: clamp(150px, 50%, 250px);
}

.nav-menu {
    background: var(--primary-color);
    width: clamp(330px, 50%, 400px);
    position: fixed;
    height: 100%;
    z-index: 1000;
    top: 87px;
}

.overlay-bg {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.75);
    width: 100%;
    height: 100%;
    z-index: 500;
}

.sidebar-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    align-items: start;
    justify-items: center;
    width: 75%;
    margin: 20px auto;
    /* height: calc(100vh - 127px); */
    min-height: calc(100vh - 127px);
    min-height: calc(100dvh - 127px);
    grid-auto-rows: 60px 60px 60px 60px 60px 1fr;
}

.sidebar-flex-container {
    display: flex;
    width: 100%;
    max-width: 300px;
    align-items: center;
    gap: 20px;
    transition: .2s;
    padding: 10px 20px;
    border-radius: 10px;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 1.1px;
    word-break:normal;
}

.sidebar-flex-container.active {
    background-color: #ffffff4b;
    color: white;
}

.sidebar-flex-container:hover {
    background-color: #ffffff4b;
    color: white;
}

.sidebar-flex-container .sidebar-icons {
    font-size: 40px;
    color: white;
    font-variation-settings: 'FILL' 0, 'wght' 200, 'GRAD' 0, 'opsz' 24;
}

/* TODO */
.todos-container {
display: grid;
max-width: 1000px;
grid-template-columns: 1fr 1fr;
gap: 20px;
margin: 30px auto;
justify-items: center;
}

.single-todo-container {
    background: rgb(255, 255, 255);
    /* width: clamp(100px, 100%, 800px); */
    width: 100%;
    padding: 20px 50px 20px 20px;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 10px;
    position: relative;
    border-bottom: 2px solid transparent;
    word-break: break-word;
    overflow-wrap: break-word;
}

#close {
    color: rgb(255, 0, 0);
    position: absolute;
    top: 10px;
    right: 10px;
}

.single-todo-container .date {
    color: rgb(197, 197, 197);
    font-size: 14px;
    font-style: italic;
    font-weight: 300;
    padding-top: 20px;
    margin: auto 0 0 0;
}

.inner-flex-single-todo {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.single-todo-container.todo-active {
    border-bottom: 2px solid var(--primary-color);
}

.icons-container {
   position: absolute;
   bottom: 10px;
   right: 6px;
}

.icons-container .checkmark {
    cursor: pointer;
    user-select: none;
    font-size: 30px;
}

/* Todo/Login/Register FORMS */ 
.login-maskott-img {
    width: clamp(100px, 40%, 200px);
    margin: auto;
}

/* LOGIN & REGISTER FORMS */
.login-register-padding {
    padding: 30px;
}

.fancy-form {
    padding: 20px 60px 20px 60px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background-color: white;
    margin: 0 auto;
    /* width: clamp(300px, 60%, 800px); */
    max-width: 500px;
    gap: 25px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.fancy-form-title {
    text-align: center;
    font-weight: 700;
    font-size: 30px;
    color: var(--primary-color);
}

.fancy-input-container {
    position: relative;
    display: flex;
    align-items: center;
}

.fancy-form input {
    width: 100%;
    padding: 10px 15px;
    border: 1px solid rgb(136, 136, 136);
    background-color: white;
    font-size: 16px;
    font-weight: 400;
    transition: 300ms ease;
    border-radius: 5px;
}

.fancy-form input:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.fancy-form input:hover {
    border: 1px solid var(--primary-color);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.fancy-form label {
    position: absolute;
    display: flex;
    align-items: center;
    height: 50%;
    left: 10px;
    padding: 0 5px;
    transition: 300ms ease;
    pointer-events: none;
    font-size: 16px;
}

.fancy-input-container input:focus + label, 
.fancy-input-container input:valid + label {
    transform: translateY(-100%);
    color: var(--primary-color);
    font-size: 14px;
    background-color: white;
}

.fancy-form button {
    width: 100%;
    border-radius: 30px;
    border: none;
    padding: 10px;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 16px;
    color: rgb(255, 255, 255);
    background: var(--primary-color);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 10px;
    cursor: pointer;
    outline: none;
    transition: transform .2s ease, filter .2s ease, box-shadow .2 ease;
}

.fancy-form button:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 10px;
    filter: brightness(1.1);
}

.fancy-form button:active {
    transform: scale(0.95);
}

.fancy-form button:focus {
    outline: 3px solid rgb(0, 0, 0);
}

.login-container {
    width: clamp(25%, 500px, 90%);
    margin: auto;
    background: white;
    padding: 20px 40px;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

form.add-todo-form,
form.register-form,
form.login-form {
    width: clamp(50%, 400px, 90%);
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

form.add-todo-form input,
form.add-todo-form textarea,
form.register-form input,
form.login-form input {
    padding: 15px;
    border: 1px solid rgb(102, 102, 102);
    border-radius: 2px;
}

form.add-todo-form input:hover,
form.add-todo-form textarea:hover,
form.register-form input:hover,
form.login-form input:hover {
background-color: rgb(224, 224, 224);
}

form.add-todo-form label,
form.register-form label,
form.login-form label {
    margin-top: 10px;
    color:rgb(70, 70, 70)
}

form.add-todo-form button,
form.register-form button,
form.login-form button {
    width: clamp(100%, 100%, 400px);
    margin: 20px auto;
    border-radius: 30px;
    border: 2px solid transparent;
    padding: 10px;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 16px;
    color: rgb(255, 255, 255);
    background: var(--secondary-color);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 10px;
    cursor: pointer;
    outline: none;
    transition: box-shadow .4s ease;
}

form.add-todo-form button:hover,
form.register-form button:hover,
form.login-form button:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 10px;
    background: #0eb368;
}

form.add-todo-form button:focus,
form.register-form button:focus,
form.login-form button:focus {
    border: 2px solid black;
} 

form.add-todo-form label:nth-of-type(2) {
    margin-top: 20px;
}

/* TODO USER PROFILE */
.colorpicker {
    margin-top: 30px;
    margin-bottom: 30px;
    border: none;
    border-radius: 5px;
    width: 100px;
    cursor: pointer;
}

.todo-profile-container {
    max-width: clamp(50%, 100%, 1000px);
    margin: 30px auto;
    padding-left: 30px;
    padding-right: 30px;
    height: 150px;
    border-radius: 10px;
    position: relative;
}

.todo-profile-container .white-circle-cutout {
    height: 160px;
    width: 160px;
    background: white;
    border-radius: 50%;
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.todo-profile-container .profile-image {
    position: absolute;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    left: 50%;
    top: 90%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    object-fit: cover;
    object-position: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    transition: all .2s ease;
    z-index: 1;
}

.todo-profile-container .profile-image:hover {
    opacity: .5;
}

.todo-profile-container .profile-placeholder {
    border-radius: 50%;
    height: 150px;
    width: 150px;
    background: var(--primary-color);
    position: absolute;
    left: 50%;
    top: 90%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 1;
    transition: all .2s ease;
}

.todo-profile-container .profile-placeholder:hover {
    opacity: .5;
}

.todo-profile-container .header-image {
    border-radius: 10px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
    transition: all .2s ease;
}

.todo-profile-container .header-image:hover {
    opacity: .5;
}

.todo-profile-container .header-placeholder {
    border-radius: 10px;
    height: 100%;
    width: 100%;
    background-color: var(--primary-color);
    cursor: pointer;
    transition: all .2s ease;
}

.todo-profile-container .header-placeholder:hover {
    opacity: .5;
}

.profile-flex-container {
    display: flex;
    flex-direction: column;
    max-width: clamp(50%, 100%, 1000px);
    padding: 0 30px;
    margin: 80px auto;
    position: relative;
    align-items: center;
}

.profile-flex-container .info-dump {
    display: grid;
    width: 100%;
    justify-items: center;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: 
        "title1 title2 title3"
        "state1 state2 state3";
    margin-top: 30px;
    background: rgb(250, 250, 250);
    padding: 20px 0;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.info-title.one {
    grid-area: title1;
}

.info-title.two {
    grid-area: title2;
}

.info-title.three {
    grid-area: title3;
}

.info-state:nth-of-type(1) {
    grid-area: state1;
}

.info-state:nth-of-type(2) {
    grid-area: state2;
}

.info-state:nth-of-type(3) {
    grid-area: state3;
}

.info-dump .info-title {
    color: rgb(189, 189, 189);
}

.info-dump .info-state {
    font-size: 2rem;
    color: rgb(39, 39, 39);
    font-weight: 400;
}

.profile-flex-container .delete-profile {
    width: 150px;
    padding: 10px;
    border-radius: 10px;
    margin: 30px;
    margin-bottom: 200px;
    border: none;
    outline: none;
    background-color: rgb(209, 209, 209);
    color: white;
    letter-spacing: 1px;
    cursor: pointer;
    text-transform: uppercase;
    transition: all .3s ease;
}

.profile-flex-container .delete-profile:hover {
    transform: scale(1.2);
    background-color: red;
}

.profile-flex-container .titles {
    text-align: center;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 55px;
}
.profile-flex-container .titles .profile-username {
    color: rgb(0, 0, 0);
    font-size: 1.4rem;
    font-weight: 700;
    cursor: pointer;
}
.profile-flex-container .titles .profile-email {
    color: rgb(189, 189, 189);
    font-size: .8rem;
    font-weight: 400;
    cursor: pointer;
}

/* PROFILE EDIT MODE */
.profile-flex-container .titles input {
    width: 100%;
}

.profile-flex-container .titles .profile-edit {
    position: absolute;
    top: 50%;
    left: 90%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    user-select: none;
    cursor: pointer;
    background: white;
}

#editUsername {
    outline: none;
    border: none;
    text-align: center;
    color: rgb(0, 0, 0);
    font-size: 1.4rem;
    font-weight: 700;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

#editEmail {
    color: rgb(189, 189, 189);
    font-size: .8rem;
    font-weight: 400;
    outline: none;
    border: none;
    text-align: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: black;
}

/* PROFILE DELETE PROMPT */
.profile-dimmer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.profile-dimmer .delete-prompt {
    display: flex;
}

.delete-prompt button:first-child {
    color: red;
}
.delete-prompt button:last-child {
    color: var(--primary-color)
}

.profile-dimmer .delete-prompt button {
    padding: 20px;
    margin: 20px 10px;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
}


.profile-dimmer .delete-prompt-text {
    width: 300px;
    background: rgb(255, 0, 0);
    text-align: center;
    padding: 20px 30px;
    border-radius: 5px;
    color: white;
    letter-spacing: 1px;
    user-select: none;
}

/* PROFILE TOOLS */
.profile-header-tools-container {
    position: absolute;
    top: 20px;
    left: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    padding: 5px 10px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.profile-image-tools-container {
    position: absolute;
    top: 90%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    padding: 5px 10px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    transform: translate(-50%, -50%);
    z-index: 10;
}

/* Error Page */
.errorpage {
    width: clamp(50%, 90%, 900px);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -50px;
}

.notfound-grid {
    display: grid;
    grid-template-rows: auto 1fr;
}

.loader-list-container {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loader-list-container .load-list-text {
    font-weight: 700;
    margin-top: 20px;
    font-size: 20px;
    color: var(--primary-color);
}

@media screen and (max-width: 500px) { 

    .standard-h1 {
        font-size: 50px;
    }

     .navbar-header {
        grid-template-columns: 1fr 1fr;
        gap: 5px;
    }

    .todo-profile-container .header-image:hover,
    .todo-profile-container .header-placeholder:hover,
    .todo-profile-container .profile-image:hover,
    .todo-profile-container .profile-placeholder:hover {
        opacity: 1;
    }

    .profile-link {
        display: none;
    }

    .nav-menu {
        width: 100%;
    }

    .spacer {
        height: 183px;
    }

    .list-overview-grid {
        grid-template-columns: 1fr ;
    }

    .fancy-form {
        padding: 20px 30px;
        box-shadow: none;
    }

    .fancy-form button:hover {
        filter: none;
        box-shadow: none;
    }

    .login-register-padding {
        padding: 0;
    }

    .add-list-form[data-isopen="true"] {
        padding: 20px 30px;
    }

    .add-list-form button:hover {
        box-shadow: none;
        filter: none;
    }

    .add-list-form button:focus {
        outline: none;
    }

    .profile-flex-container .info-dump {
        grid-template-columns: 1fr;
        grid-template-areas: 
            "title1"
            "state1"
            "title2"
            "state2"
            "title3"
            "state3";
    }
    .profile-flex-container .titles input {
        width: 80%;
    }

    .profile-flex-container {
        margin-bottom: 30px;
    }

    .profile-dimmer {
        height: 100%;
        top: 90px;
    }

    .material-symbols-rounded profile-edit,
    .material-symbols-rounded profile-edit {
        display: none;
    }

    .list-info-container {
        grid-template-areas:
        "title title"
        "left right";
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto 1fr;
    }

    .single-todo-oppgaver {
        grid-area: left;
    }

    .single-todo-title {
        grid-area: title;
    }

    .single-todo-ferdig {
        grid-area: right;
    }

    .todos-container {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        margin: 30px 0;
        width: 100%;
        }
    
    .drag-container {
        width: calc(100% - 60px);
        margin: 30px auto;
    }
}