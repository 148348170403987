/* .drawer-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 210px;
    background-color: rgba(255, 255, 255, 0);
} */

.outer-motion {
    position: fixed;
    z-index: 50;
    background-color: rgba(0, 0, 0, 0);
    bottom: 0;
    left: 0;
    right: 0;
}

.inner-motion {
    position: absolute;
    bottom: 0;
    height: 200px;
    width: 100%;
    overflow: hidden;
    background: rgba(255, 255, 255, 0);
}

.dragContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0);
    padding: 40px;
    cursor: grab;
    touch-action: none;
    -ms-touch-action: none;
    -webkit-user-select: none; 
    -moz-user-select: none;
    -ms-user-select: none; 
    user-select: none;
}

.dragContainer:active {
    cursor: grabbing;
}

.dragContainer:active > .dragIcon {
    background-color: rgba(0, 0, 0, 0.75);
}

.dragIcon {
    height: 10px;
    width: 120px;
    background-color: rgba(0, 0, 0, 0.2);
    border: none;
    border-radius: 10px;
    transition: all .2s ease;
    outline: none;
}

/* .dragIcon:active {
    cursor: grabbing;
    background-color: rgba(0, 0, 0, 0.75);
} */

.dragIcon:hover {
    background-color: rgba(0, 0, 0, 0.75);
}

.modul-content {
    position: relative;
    top: 90px;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 110px;
}

.bottom-menu-icons {
    font-size: 60px;
    color: rgb(0, 0, 0);
    font-variation-settings: 'FILL' 0, 'wght' 300, 'GRAD' 0, 'opsz' 24;
    background-color: rgba(255, 255, 255, 0.75);
    margin: 10px;
    padding: 5px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    cursor: pointer;
    transition: all .2s ease-out;
    user-select: none;
}

.modul-content a {
    line-height: 0;
}

.modul-content a.active .bottom-menu-icons {
    color: var(--primary-color);
    text-decoration: none;
}

.bottom-menu-icons:hover {
    transform: scale(1.3);
    color: var(--primary-color);
    background-color: rgba(255, 255, 255, 1);
}

.drawer-small-bg {
    position: absolute;
    top: 50px;
    background-color: rgb(255, 255, 255);
    width: 100%;
    height: 300px;
    display: none;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

@media screen and (max-width: 500px) {
    .bottom-menu-icons {
        font-size: 60px;
        margin: 0px;
        padding: 0;
        box-shadow: none;
        text-decoration: none;
        background-color: rgba(255, 255, 255, 0);
        font-variation-settings: 'FILL' 0, 'wght' 200, 'GRAD' 0, 'opsz' 24;
    }
    .bottom-menu-icons:hover {
        transform: scale(1);
        color: var(--primary-color);
        background-color: rgba(255, 255, 255, 0);
    }
    .drawer-small-bg {
      display: block;
    }

    .modul-content {
        align-items: center;
        justify-content: space-evenly;
        background-color: white;
    }

    .dragIcon {
        width: 70%;
        transition: none;
    }

    .dragIcon:hover {
        background-color: rgba(0, 0, 0, 0.2);
    }
}